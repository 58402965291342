<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Projects">

                <b-button variant="black2" @click="addProjectModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="projects" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="openProject(props.row.id)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeProject(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>

            </b-card>
        </b-overlay>

        <b-modal title="Add project" v-model="addProjectModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Client</label>
                    <v-select
                        v-model="addObject.client_id"
                        :reduce="client => client.id"
                        label="name"
                        :options="clients"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addProjectModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addProject">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            vSelect
        },
        data() {
            return {
                dataLoaded: false,
                projectsRaw: [],
                clients: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Client',
                        displayType: 0,
                        field: 'client',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addProjectModalActive: false,
                addObject: {
                    client_id: '',
                    name: ''
                }
            }
        },
        methods: {
            openProject(id) {
                this.$router.push(`/project/${  id}`)
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/project')
                loadPromise.then(function(response) {
                    thisIns.projectsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const clientsPromise = this.$http.get('/api/management/v1/client')
                clientsPromise.then(function(response) {
                    thisIns.clients = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([clientsPromise, clientsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addProject() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/project', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Project added')
                    thisIns.addProjectModalActive = false
                    thisIns.addObject = {
                        client_id: '',
                        name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeProject(id) {
                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/project/${  id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Project removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            projects() {
                return this.projectsRaw.map((project, index) => {
                    return {
                        index,
                        id: project.id,
                        client_id: project.client_id,
                        client: project.client,
                        name: project.name
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>